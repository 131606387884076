import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "kt_account_profile_details",
  class: "collapse show"
}
const _hoisted_2 = { class: "card-body border-top p-0" }
const _hoisted_3 = { style: {"height":"64vh","width":"100%"} }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "flex-grow-1" }
const _hoisted_9 = { class: "d-flex flex-column" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_control_layers = _resolveComponent("l-control-layers")!
  const _component_l_control_zoom = _resolveComponent("l-control-zoom")!
  const _component_l_icon = _resolveComponent("l-icon")!
  const _component_l_popup = _resolveComponent("l-popup")!
  const _component_l_marker = _resolveComponent("l-marker")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_l_map, {
          modelValue: _ctx.zoom,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.zoom) = $event)),
          zoom: _ctx.zoom,
          "onUpdate:zoom": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.zoom) = $event)),
          zoomAnimation: true,
          options: { zoomControl: false },
          center: [-6.304159, 106.724997]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_l_tile_layer, {
              url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              "layer-type": "base",
              name: "OpenStreetMap"
            }),
            _createVNode(_component_l_control_layers),
            _createVNode(_component_l_control_zoom, { position: "bottomright" }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (item, index) => {
              return (_openBlock(), _createBlock(_component_l_marker, {
                key: index,
                "lat-lng": [item.person.latitude, item.person.longitude]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_l_icon, {
                    "icon-url": _ctx.icon,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["icon-url", "icon-size"]),
                  _createVNode(_component_l_popup, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("img", {
                              class: "img-fluid",
                              style: {"max_height":"100px"},
                              src: item.person.foto,
                              alt: "foto kegiatan"
                            }, null, 8, _hoisted_7),
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, _toDisplayString(item.person.name), 1),
                                _createElementVNode("div", _hoisted_11, _toDisplayString(item.person.phone_number), 1),
                                _createElementVNode("div", _hoisted_12, _toDisplayString(item.person.alamat), 1),
                                _createElementVNode("div", _hoisted_13, _toDisplayString(item.person.position), 1),
                                _createElementVNode("div", _hoisted_14, _toDisplayString(item.person.profession), 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["lat-lng"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "zoom", "center"])
      ])
    ])
  ]))
}